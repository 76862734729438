import Branding from '@components/AppLevelSideEffects/Branding';
import {Phone, Coupon} from '@components/AppLevelSideEffects/PhoneCoupon';
import Notifications from '@components/AppLevelSideEffects/Notifications';
import Partner from '@components/AppLevelSideEffects/Partner';
import Session from '@components/AppLevelSideEffects/Session';

const AppLevelConcerns = ({pageProps}: {pageProps: any}) => (
  <>
    <Session />
    <Branding />
    <Phone />
    <Coupon />
    <Notifications />
    <Partner pageProps={pageProps} />
  </>
);

export default AppLevelConcerns;
