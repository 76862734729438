type RetryOptions = {
  interval?: number;
  maxRetries?: number;
};

/**
 * Retry a callback function until it returns true or the max number of retries is reached.
 * Normally we'd use "useIntervalConditionally" hook for this, but this is a standalone function outside
 * of component lifecycle.
 *
 * @param {() => boolean} callback
 * @param {number | undefined} interval
 * @param {number | undefined} maxRetries
 */
export function retry(callback: () => boolean, {interval = 500, maxRetries = 10}: RetryOptions = {}): void {
  let attempts = 0;

  const execute = () => {
    const shouldStop = callback();

    if (shouldStop) {
      return; // Stop retrying if the callback succeeded
    }

    if (attempts < maxRetries) {
      attempts += 1;
      setTimeout(execute, interval); // Retry after specified interval
    }
  };

  execute();
}
