import Cookies from 'js-cookie';

export const BRANDING_COOKIE = '_ht_branding';
const EXPIRATION_DAYS = 365;

/**
 * @param {Object} brandData
 * @param {String} brandData.partnerName
 * @param {String} brandData.cobrandedLogo
 */
export const setBrandingCookie = (brandData: Record<string, any>) => Cookies.set(BRANDING_COOKIE, JSON.stringify(brandData), {expires: EXPIRATION_DAYS});

export const getBrandingCookie = () => {
  const value = Cookies.get(BRANDING_COOKIE);
  return value ? JSON.parse(value) : null;
};

export const removeBrandingCookie = () => Cookies.remove(BRANDING_COOKIE);
