/* base level site wide constants */

/* ------------------------------------------------- */
/* ---------------- CSS VARIABLES ------------------ */
/* ------------------------------------------------- */
export const HEADER_HEIGHT = '72px';

/* ------------------------------------------------- */
/* ---------------- NAVIGATIONS -------------------- */
/* ------------------------------------------------- */
export const NAVIGATION_CATEGORY_TYPE_SERVICE = 'service';
export const NAVIGATION_CATEGORY_TYPE_PRODUCT = 'product';

/* ------------------------------------------------- */
/* ---------------- ID's --------------------------- */
/* ------------------------------------------------- */
export const HEADER_ID = 'ht-main-header';
export const HEADER_PORTAL_ID = 'ht-header-portal';
export const HEADER_WRAPPER_ID = 'ht-header-wrapper';

/* ------------------------------------------------- */
/* ---------------- ENVIRONMENT --------------------- */
/* ------------------------------------------------- */
export const PRODUCTION = 'production';
export const STAGING = 'staging';
export const DEVELOPMENT = 'development';

/* ------------------------------------------------- */
/* ---------------- STATUSES ----------------------- */
/* ------------------------------------------------- */
/* SERVER RESPONSES ERRORS */
export const StatusErrors = new Map([
  [400, 400],
  [404, 404],
  [500, 500],
]);

export const StatusSuccess = new Map([[200, 200]]);

/* ---------------- END STATUSES ----------------------- */

/* */
export const Languages = new Map([['us', 'en_us']]);

/*  ----------------- MISC ------------------------------- */
export const autoCloseToast = 5000;
export const HIDE_PRODUCT_CONNECTED_HOME = true;

