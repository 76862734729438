/* Libs */
import Segment from '../index';

/* Utils */
import { sleep } from '@utils/helpers';

/* Types */
import {User} from 'types/user.types';

/**
 * -------------------------------------------------------------------------------------
 * Define the "event name" here, in TRACKING_TYPES
 */
export const TRACKING_TYPES = {
  userSignedUp: 'registrationPage/USER_SIGNED_UP',
};

/**
 * -------------------------------------------------------------------------------------
 * These are just "actions" that are consolidated in one place that
 * will return your properties structure. Just "method" out what you
 * need to handle.
 */
export const SegmentTrackingAction = {
  async userSignedUp(attributes: Partial<User>) {
    const payload = {
      email: attributes.email,
      first_name: attributes.firstName,
      last_name: attributes.lastName,
      id: attributes.id,
    };

    await sleep(2000);
    Segment.identify(attributes.id as string, payload);
  },
};
