
function QuoraPixel() {
  return (
    <>
      <img style={{display: 'none'}} alt="quora pixel tracking" height="1" width="1" src="https://q.quora.com/_/ad/9d5f1c6576fa4b7895567d9fb000e8f6/pixel?tag=ViewContent" />
    </>
  );
}

export default QuoraPixel;

