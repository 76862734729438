import { createWithEqualityFn } from 'zustand/traditional';
import { immer } from 'zustand/middleware/immer';
// import { FullStoryEvents } from '@integrations/analytics/FullStory';
// import { GtagEvents } from '@integrations/analytics/google.gtag';

/* TYPES */
export type TrackEventPayload = {
  eventType: string;
  identifier?: string; // Optional identifier for user
  data: Record<string, any>; // Object representing key-value pairs
  analyticsType: string; // This will specify which analytics tool is being used
};

export type AnalyticsState = {
  analyticsInitialized: boolean;
  eventsQueue: Record<string, Array<TrackEventPayload>>; // Queue by analytics type

  actions: {
    initializeAnalytics: (analyticsType: string) => void;
    trackEvent: (payload: TrackEventPayload) => void;
    flushQueue: (analyticsType: string) => void;
  };
};

/* CONSTANTS */
export const ANALYTICS_FULLSTORY = 'FullStory';
export const ANALYTICS_GOOGLE = 'GoogleAnalytics';
export const ANALYTICS_SEGMENT = 'SegmentAnalytics';

/* ----------------------------------------------------------------------- */
/* ---------------------  ANALYTICS STORE SETUP  ----------------------- */
/* ----------------------------------------------------------------------- */

const useAnalyticsStore = createWithEqualityFn<AnalyticsState>()(
  immer((set) => ({
    analyticsInitialized: false,
    eventsQueue: {}, // Initialize the queue as an empty object

    actions: {
      /* Not used */
      initializeAnalytics: (analyticsType) => {},

      /* Handle if its loaded in the caller. TrackEvent is a que */
      trackEvent: ({ eventType, identifier, data, analyticsType }: TrackEventPayload) => {
        set((state) => {
            /* Lets not update if identifier (uid, or track event) is currently que'd */
           const currentQue = state.eventsQueue[analyticsType] || [];
           const eventExists = Boolean(currentQue.find((event) => event.identifier === identifier));
           if (!eventExists) {
             const currentQueRef = state.eventsQueue[analyticsType] || [];
             // FullStory specific call
             if (analyticsType === ANALYTICS_FULLSTORY) {
               state.eventsQueue[ANALYTICS_FULLSTORY] = currentQueRef.concat([{ eventType, identifier, data, analyticsType }]);
             } else if (analyticsType === ANALYTICS_GOOGLE) {
               // Google Analytics specific call
               state.eventsQueue[ANALYTICS_GOOGLE] = currentQueRef.concat([{ eventType, identifier, data, analyticsType }]);
             } else if (analyticsType === ANALYTICS_SEGMENT) {
               // Segment Analytics specific call
               state.eventsQueue[ANALYTICS_SEGMENT] = currentQueRef.concat([{ eventType, identifier, data, analyticsType }]);
             }
           }
        });
      },

      flushQueue: (analyticsType: string) => {
        set((state) => {
          if (state.eventsQueue[analyticsType]?.length) {
            // Flush the queue for the specified analytics type
            state.eventsQueue[analyticsType].forEach(({ eventType, identifier, data }) => {
              if (analyticsType === ANALYTICS_FULLSTORY) {
                // const d = Array.isArray(data) ? data.at(0) : data;
                // FullStoryEvents({ eventType, identifier, data: d });
              } else if (analyticsType === ANALYTICS_GOOGLE) {
                // const d = Array.isArray(data) ? data.at(0) : data;
                // GtagEvents({eventType, identifier, data: d});
              } else if (analyticsType === ANALYTICS_SEGMENT) {
                // @ts-ignore
                window?.analytics?.[eventType](identifier, ...data)
              }
              // Other analytics flush logic
            });
            state.eventsQueue[analyticsType] = []; // Clear the queue after flushing
          }
        });
      },
    },
  }))
);

/* Export Selectors */
export const useAnalyticsActions = () => useAnalyticsStore((state) => state.actions);
export const useAnalyticsInitialized = () => useAnalyticsStore((state) => state.analyticsInitialized);
export const useEventsQueue = (analyticsType: string) => useAnalyticsStore((state) => state.eventsQueue[analyticsType]);

export default useAnalyticsStore;
