import {useEffect} from 'react';
import { useTrackingActions, useTrackingPhone } from '@store/client/store.tracking';
import {useRouter} from 'next/router';
import {pushToDataLayer} from '@integrations/analytics/google.gtag';
import {getBrandingCookie, removeBrandingCookie} from '@utils/cookies/branding';
import {useUserHasSubscriptionSelector} from '@store/queries/user/useUser.selector';

import useUserCache from '@store/queries/user/useUser.cache';
import {removePlanSubscriberPhone} from '@scenes/LoginPage/login.cookies';
import {setUserPlanBrandingAndCookies} from '@integrations/partners/branding/branding.utils';
import {User} from 'types/user.types';

/**
 * This handles the phone tracking for the app as it relates to Google Analytics
 * This is the equivalent of (as it relates to phone) in the reducer/tracking.js file in client.
 *
 * Firing GA wevent
 * 1. When phone changes
 * 2. When cookie is read for phones
 *
 * @returns {null}
 * @constructor
 */
const Branding = () => {
  /* Client State */
  const phoneState = useTrackingPhone();
  const {setPhoneState} = useTrackingActions();

  /* Query */
  const user = useUserCache();
  const userHasSubscription = useUserHasSubscriptionSelector();
  const userPartnerSubscription = getBrandingCookie();

  /* Hooks */
  const router = useRouter();
  const {phone_number: phoneNumber, phone: queryPhone} = router.query;

  /* Constants */
  const phone = phoneNumber || queryPhone;

  /**
   * Manage Side Effects
   * - BRANDING COOKIE & REMOVE PLAN SUBSCRIPTION PHONE
   *   1. Set phone state
   *   2. Set branding query object
   *   3. Remove branding cookie
   *   4. Remove plan subscriber phone
   * */
  useEffect(() => {
    if (userPartnerSubscription?.id) {
      /* 1. */
      setPhoneState(userPartnerSubscription.phone);
      /* 2. */
      const u = {account: {subscription: {planId: null, partner: userPartnerSubscription}}} as User;
      setUserPlanBrandingAndCookies(u);
    }

    /* User no longer has a subscription */
    if (user?.id && !userHasSubscription) {
      /* 3. */
      removeBrandingCookie();
      /* 4. */
      removePlanSubscriberPhone();
    }
  }, [userPartnerSubscription?.id, user]);

  /**
   * PH
   *
   * */
  useEffect(() => {
    if (phoneState) {
      /* 2. Send to dataLayer */
      if (phoneState) {
        pushToDataLayer({phone_conversion_number: phoneState.title});
      }
    }
  }, [phoneState]);

  /**
   * If we have a phone in the url, we should set with a precedence.
   * Set in state, cookie and send to dataLayer if:
   * 1. We have a phone in the url
   * 2. We don't
   */
  useEffect(() => {
    if (!phoneState && phone) {
      /* 1. Send to dataLayer */
      // pushToDataLayer({ phone_conversion_number: phone });
      /* 2. Save to state */
    }
  }, [phone]);

  return null;
};

export default Branding;
