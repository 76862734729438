import {checkIsBotUA, checkIsFaceBookBot} from '@utils/ui';
import Script from 'next/script';
import { ANALYTICS_SEGMENT, useAnalyticsActions } from '@integrations/analytics/store/store.analytics';

const SegmentAnalyticsScript = () => {
  /* Analytic Actions */
  const analyticsActions = useAnalyticsActions();

  const isBot = checkIsBotUA();
  /* facebook has a bot, but doesn't include bot in name */
  const isFacebookBot = checkIsFaceBookBot();

  if (isBot || isFacebookBot) {
    return null;
  }

  return (
    <>
      <Script
        src={`https://cdn.segment.com/analytics.js/v1/${process.env.NEXT_PUBLIC_SEGMENT_KEY}/analytics.min.js`}
        strategy="lazyOnload"
        onReady={() => {
          setTimeout(() => {
            analyticsActions.flushQueue(ANALYTICS_SEGMENT)
          }, 1000);
        }}
      />
    </>
  );
};

export default SegmentAnalyticsScript;

