// import Script from 'next/script';
//
// const AccessibeScript = () => (
//   <>
//     {/* Preload script */}
//     <link
//       rel="preload"
//       href="https://acsbapp.com/apps/app/dist/js/app.js"
//       as="script"
//     />
//
//     {/* Load the script without blocking the main thread */}
//     <Script
//       id="accessibe-init"
//       src="https://acsbapp.com/apps/app/dist/js/app.js"
//       strategy="lazyOnload" // Ensure it executes after page load
//       onLoad={() => {
//         if (window?.acsbJS && typeof window.acsbJS.init === 'function') {
//           if (window?.acsbJS) {
//             window.acsbJS.init({
//               statementLink: '',
//               footerHtml: '',
//               hideMobile: false,
//               hideTrigger: false,
//               disableBgProcess: false,
//               language: 'en',
//               position: 'left',
//               leadColor: '#007b83',
//               triggerColor: '#007b83',
//               triggerRadius: '50%',
//               triggerPositionX: 'left',
//               triggerPositionY: 'bottom',
//               triggerIcon: 'people',
//               triggerSize: 'medium',
//               triggerOffsetX: 32,
//               triggerOffsetY: 32,
//               mobile: {
//                 triggerSize: 'small',
//                 triggerPositionX: 'right',
//                 triggerPositionY: 'center',
//                 triggerOffsetX: 16,
//                 triggerOffsetY: 0,
//                 triggerRadius: '50%',
//               },
//             });
//           }
//         }
//       }}
//     />
//   </>
// );

import {useEffect} from 'react';
import {SCRIPT_LOAD_TIME_ACS} from '@integrations/integrations.constants';

const AccessibeScript = () => {
  useEffect(() => {
    let scriptLoaded = false;

    const loadAccessibeScript = () => {
      if (!window.acsbJS && !window.acsb && !scriptLoaded) {
        const script = document.createElement('script');
        script.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
        script.async = true;
        script.onload = () => {
          if (window?.acsbJS && typeof window.acsbJS.init === 'function') {
            window.acsbJS.init({
                statementLink: '',
                footerHtml: '',
                hideMobile: false,
                hideTrigger: false,
                disableBgProcess: false,
                language: 'en',
                position: 'left',
                leadColor: '#007b83',
                triggerColor: '#007b83',
                triggerRadius: '50%',
                triggerPositionX: 'left',
                triggerPositionY: 'bottom',
                triggerIcon: 'people',
                triggerSize: 'medium',
                triggerOffsetX: 32,
                triggerOffsetY: 32,
                mobile: {
                  triggerSize: 'small',
                  triggerPositionX: 'right',
                  triggerPositionY: 'center',
                  triggerOffsetX: 16,
                  triggerOffsetY: 0,
                  triggerRadius: '50%',
                },
            });
          }
        };
        document.body.appendChild(script);
        scriptLoaded = true;
      }
    };

    /* Have our initial delay */
    const delayLoadingScript = () => {
      setTimeout(loadAccessibeScript, SCRIPT_LOAD_TIME_ACS);
    };

    /*
        If use interacts with page before it's loaded, load the script immediately.
     */
    const triggerLoadOnInteraction = () => {
      loadAccessibeScript();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      removeInteractionListeners();
    };

    /* Add event listeners for user interactions */
    const addInteractionListeners = () => {
      window.addEventListener('load', delayLoadingScript);
      ['scroll', 'mousemove', 'touchstart'].forEach(event => {
        window.addEventListener(event, triggerLoadOnInteraction, { once: true });
      });
    };

    /* Remove event listeners once the script is loaded */
    const removeInteractionListeners = () => {
      window.removeEventListener('load', delayLoadingScript);
      ['scroll', 'mousemove', 'touchstart'].forEach(event => {
        window.removeEventListener(event, triggerLoadOnInteraction);
      });
    };

    /* Load the script after the page has loaded */
    if (typeof window !== 'undefined') {
      addInteractionListeners();
    }

    /* Remove event listeners */
    return () => {
      removeInteractionListeners();
    };
  }, []);

  return <link rel="preconnect" href="https://acsbapp.com" />;
};

export default AccessibeScript;

