import Bugsnag from '@bugsnag/js';
import {queryClient} from '@integrations/reactquery';
import {DEVELOPMENT, STAGING} from '@constants/base';
import {userQueryKeys} from '@store/queries/user/queries.keys';
import {getZipDataCookie} from '@utils/cookies/zipCodeTestCookie';
import segmentAnonymousId from '@utils/cookies/segmentAnonymousId';

const bufferFrom = require('buffer-from');

class ResponseError extends Error {
  response?: Response;

  constructor(response?: Response) {
    super(response ? response.statusText : 'Offline');
    this.response = response;
  }
}

type RequestOptions = RequestInit & {
  headers: HeadersInit;
};

type AuthData = {
  formAuthenticityToken?: string;
  authToken?: string;
  cookie?: string;
};

/**
 * Checks the HTTP response status to determine if it indicates a successful request.
 *
 * This function evaluates the `ok` property of the `Response` object, which is true if
 * the response's status is in the range of 200-299, indicating a successful HTTP request.
 * If the response status is outside this range, it's considered an unsuccessful request,
 * and the function throws a `ResponseError` with the `Response` object.
 *
 * @param {Response} response - The response object from the fetch API.
 * @returns {Promise<Response>} A promise that resolves with the response object if the request was successful.
 * @throws {ResponseError} Thrown when the response status code indicates an unsuccessful request.
 */
async function checkStatus(response: Response): Promise<Response> {
  if (response.ok) {
    return response;
  }

  throw new ResponseError(response);
}

/**
 * Parses the HTTP response and returns the appropriate data format.
 *
 * This function checks the HTTP response status and determines the format in which
 * to parse the response body. For responses with a 204 status code (No Content), it reads
 * the response body as text. For all other response types, it parses the response body as JSON.
 *
 * Note: This function assumes that non-204 responses will be in JSON format.
 *
 * @param {Response} response - The response object from the fetch API.
 * @returns {Promise<any>} A promise that resolves with the parsed response data.
 *                         The data will be a string for 204 responses, or a parsed JSON object for others.
 */
async function parseResponse(response: Response): Promise<any> {
  return response.status === 204 ? response.text() : response.json();
}

/**
 * Performs an HTTP request using the fetch API and returns the parsed response.
 *
 * This function abstracts the standard fetch API to simplify HTTP requests. It first attempts to
 * fetch the resource at the specified URL using the provided options. It then checks the response
 * status and parses the response body according to the status code.
 *
 * If the response status indicates an unsuccessful request (status code outside the range 200-299),
 * a `ResponseError` is thrown. This error is then caught, and the response is parsed as JSON. An
 * error with the parsed error response and status details is thrown for further handling.
 *
 * In case of network errors or other fetch-related issues, the original error is thrown.
 *
 * @param {string} url - The URL to which the request is sent.
 * @param {RequestOptions} options - The options for the fetch request, including method, headers, body, etc.
 * @returns {Promise<any>} A promise that resolves with the parsed response data if the request is successful.
 * @throws {Error} Throws an error with a JSON string containing error details and response status if the request fails.
 */
export default async function request(url: string, options: RequestOptions): Promise<any> {
  try {
    const response = await fetch(url, options);
    await checkStatus(response);
    const parsedResponse = await parseResponse(response);
    return {data: parsedResponse};
  } catch (err) {
    if (err instanceof ResponseError) {
      if (!err.response) {
        console.log('offline'); // eslint-disable-line
        Bugsnag.notify('offline');
        return {err, data: {}, offline: true, status: 500};
      }
      return err.response
        .json()
        .then(data => ({err, data}))
        .catch(e =>
          // parse error
          ({err: e, data: {}}),
        );
    }

    return {err, data: {}};
  }
}

/**
 * Creates and configures options for an HTTP request.
 *
 * This function prepares the RequestOptions object needed for the fetch API call. It sets up
 * the HTTP method, headers (including various authentication and tracking tokens), and the
 * request body. The function also handles environment-specific configurations, such as
 * credentials mode and additional headers for different deployment environments (e.g., staging).
 *
 * @param {string} method - The HTTP method to be used for the request (e.g., 'GET', 'POST').
 * @param {any} [body] - Optional body data for the request. If present, it is stringified into JSON.
 *                       This object can also include an 'authenticity_token' if available.
 * @returns {RequestOptions} - The RequestOptions object, conforming to the fetch API requirements.
 *                             This includes the method, headers, body, and credentials mode.
 *
 * Note: The body parameter, if provided, is augmented with an 'authenticity_token' (if available).
 *       Headers may include a cookie, an auth token, an anonymous ID from Segment, and a zip code,
 *       depending on the available data and the current environment.
 */
function createRequestOptions(method: string, body?: any): RequestOptions {
  const {formAuthenticityToken, authToken, cookie} = queryClient.getQueryData<AuthData>(userQueryKeys.auth) || {};
  const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (cookie) {
    headers.cookie = cookie;
  }

  if (authToken) {
    headers['auth-token'] = authToken;
  }

  if ((process.env.NODE_ENV as string) === STAGING) {
    headers.Authorization = `Basic ${bufferFrom('hellotech:HTCebu').toString('base64')}`;
  }

  const ajsAnonymousId = segmentAnonymousId();
  const {zipCode} = getZipDataCookie() || {};
  if (ajsAnonymousId) {
    headers['Ajs-Anonymous-Id'] = ajsAnonymousId;
  }

  if (zipCode) {
    headers.cz = zipCode;
  }

  return {
    method,
    headers,
    body: body
      ? JSON.stringify({
          authenticity_token: formAuthenticityToken || null,
          ...body,
        })
      : undefined,
    credentials: process.env.NODE_ENV === DEVELOPMENT ? 'omit' : 'include',
  };
}

/**
 * Sends a POST request to the specified URL with the given body.
 *
 * This function is a wrapper around the fetch API for making POST requests. It creates
 * request options using the `createRequestOptions` function, sets the HTTP method to 'POST',
 * and merges any additional options provided.
 *
 * @param {string} url - The URL to which the POST request is sent.
 * @param {any} [body={}] - The data to be sent in the request body. Defaults to an empty object.
 * @param {RequestOptions} [options={ headers: {} }] - Optional additional request options.
 * @returns {Promise<any>} - A promise resolving to the response of the request.
 */
export function postJson(url: string, body: any = {}, options: RequestOptions = {headers: {}}): Promise<any> {
  return request(url, {...createRequestOptions('POST', body), ...options});
}

/**
 * Sends a PUT request to the specified URL with the given body.
 *
 * This function is used for making PUT requests to update data at the specified URL.
 * It sets up the request options for a PUT method, including the request body and any
 * additional options provided.
 *
 * @param {string} url - The URL to which the PUT request is sent.
 * @param {any} [body={}] - The data to be sent in the request body. Defaults to an empty object.
 * @param {RequestOptions} [options={ headers: {} }] - Optional additional request options.
 * @returns {Promise<any>} - A promise resolving to the response of the request.
 */
export function putJson(url: string, body: any = {}, options: RequestOptions = {headers: {}}): Promise<any> {
  return request(url, {...createRequestOptions('PUT', body), ...options});
}

/**
 * Sends a GET request to the specified URL with the given body.
 *
 * This function is used for making PUT requests to update data at the specified URL.
 * It sets up the request options for a PUT method, including the request body and any
 * additional options provided.
 *
 * @param {string} url - The URL to which the PUT request is sent.
 * @param {any} [body={}] - The data to be sent in the request body. Defaults to an empty object.
 * @param {RequestOptions} [options={ headers: {} }] - Optional additional request options.
 * @returns {Promise<any>} - A promise resolving to the response of the request.
 */
export function getJson(url: string, body: any = {}, options: RequestOptions = {headers: {}}): Promise<any> {
  return request(url, {...createRequestOptions('GET', body), ...options});
}

/**
 * Sends a DELETE request to the specified URL with the given body.
 *
 * This function is used for making DELETE requests, typically used to delete resources at
 * the specified URL. It sets up the request options for the DELETE method, including the body
 * and any additional options provided.
 *
 * @param {string} url - The URL to which the DELETE request is sent.
 * @param {any} [body={}] - The data to be sent in the request body. Defaults to an empty object.
 * @param {RequestOptions} [options={ headers: {} }] - Optional additional request options.
 * @returns {Promise<any>} - A promise resolving to the response of the request.
 */
export function deleteJson(url: string, body: any = {}, options: RequestOptions = {headers: {}}): Promise<any> {
  return request(url, {...createRequestOptions('DELETE', body), ...options});
}

/**
 * Converts an object into a URL query string.
 *
 * This function takes an object and converts it into a query string format, suitable for
 * appending to URLs in GET requests. It handles nested objects and arrays, encoding them
 * appropriately. If a prefix is provided, it is used as a namespace for the keys.
 *
 * @param {Record<string, any>} obj - The object to be converted into a query string.
 * @param {string} [prefix] - Optional prefix used for namespacing nested object keys.
 * @returns {string} - The resulting query string.
 */
export function toQueryString(obj: Record<string, any>, prefix?: string): string {
  return Object.keys(obj)
    .map(key => {
      const fullKey = prefix ? `${prefix}[${key}]` : key;
      const value = obj[key];
      return typeof value === 'object' && value !== null
        ? toQueryString(value, fullKey)
        : `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
    })
    .join('&');
}
