import Script from 'next/script';

function GoogleTracking() {
  return (
    <>
      {/* NOTE: Removed Party Town - some odd errors
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_KEY}`}
      />

      <Script
        id="gtag-init-1"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            window.gtag = function(){
              dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_KEY}', {
              cookie_update: true,
            });
          `,
        }}
      />

       GTM Script 2 with/o Partytown
      <Script
        strategy="lazyOnload"
        src="https://www.googletagmanager.com/gtag/js?id=AW-949056333"
      /> */}

      <Script
        id="gtag-init-2"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag(){window.dataLayer.push(arguments);};

            window.gtag('set', 'linker', {
              'domains': ['help.hellotech.com']
            });

            window.gtag('js', new Date());

            window.gtag('config', 'AW-949056333', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
}

export default GoogleTracking;




// export default GAScript;

/*
 THAT IS HARD CODED - I CAN JUST LAZY LOAD THIS AND REMOVE FROM SEGMENT 0R BRING THRU PARTYTOWN

<script async src="https://www.googletagmanager.com/gtag/js?id=AW-949056333"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('set', 'linker', {
      'domains': ['help.hellotech.com']
    });
    gtag('js', new Date());
    gtag('config', 'AW-949056333');
  </script>
 */
