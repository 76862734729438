import {TrackEventPayload} from '@integrations/analytics/store/store.analytics';
import {retry} from '@utils/retry';

export const pageview = (url: string) => {
  if (window.gtag) {
    window?.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_KEY, {
      page_path: url,
    });
  }
};

export function setUserProperties(userId: string, data: Record<string, any>) {
  if (window?.gtag) {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_KEY, {
      user_id: userId,
      ...data,
    });
  }
}
/**
 * PLEASE NOTE: TODO: We should add a batcher just in case the event happen before the gtag is loaded
 */
/* Push to google dataLayer */
export const pushToDataLayer =
  (params: Record<string, any>) => {
    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (key === 'phone_conversion_number' && value && value !== 'undefined') {
        acc[key] = value;
      } else if (value) {
        acc[key] = value;
      }

      return acc;
    }, {});

    retry(
      () => {
        if (window?.gtag && Object.keys(validParams).length > 0) {
          window.gtag('config', 'AW-949056333/tuMMCI-53oIBEM3mxcQD', validParams);
          return true;
        }
        return false;
      },
      {interval: 500, maxRetries: 20},
    );
  };

export const GtagEvents = ({eventType = '', identifier = '', data = []}: Omit<TrackEventPayload, 'analyticsType'>) => {
  if (eventType === 'page' && data?.path) {
    pageview(data?.path);
  }

  if (eventType === 'identify' && identifier) {
    setUserProperties(identifier!, data);
  }
};
