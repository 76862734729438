import React from 'react';
import GAScript from './GoogleAnalytics';
import SegmentScript from './SegmentAnalytics';
import NextDoor from './NextDoor';
import AccessibeScript from '@integrations/accessibe';
// import FullStoryScript from '@integrations/analytics/FullStory';
import QuoraPixel from './Quora';

const ThirdParty = () => (
  <>
    <SegmentScript />
    <NextDoor />
    <AccessibeScript />
    {/* <FullStoryScript /> */}
    <GAScript />
    <QuoraPixel />
    {/* Puls and Handy do not use cookie consent. We should have a seperate page */}
    {/* <OneTrustScript /> */}
  </>
);

export const ThirdPartyWebWorkers = () => (
  <>
      {/* Puls and Handy do not use cookie consent. We should have a seperate page */}
      {/* <OneTrustScript /> */}
  </>
);

export default ThirdParty;
