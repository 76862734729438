import React, {useEffect, useState} from 'react';
import {DefaultSeo} from 'next-seo';
import {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import NProgress from 'nprogress';
import {SplitFactoryProvider} from '@splitsoftware/splitio-react';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import cn from 'classnames';

/* Libs/integrations */
import {getKeyAJSKeyForConfig, index} from '@integrations/split';
import {queryClient, QueryClientProvider, HydrationBoundary} from '@integrations/reactquery';
import ClassSegment from '@integrations/segment';
import ClientBugsnag from '@integrations/bugsnag';
import * as gtag from '@integrations/analytics/google.gtag';

/* Components */
import AppLevelSideEffects from '@components/AppLevelSideEffects';

/* Utils */
import {GlobalSettingsLoad, GlobalSettingsUnLoad} from '@components/App/globalConcerns/app.globalsettings';

/* App Store: BaseLayoutContent */
import {useAppOpenSlideModalLogin, useAppStoreActions} from '@store/client/store.app';

/* Hooks */
import useIntervalConditionally from '@hooks/useIntervalConditionally';

/* Types and Interfaces */
import {IMyAppProps} from 'src/pages/_app';

/* Misc */
import SEO from '../../../next-seo.config';
import {MODAL_LOGIN_PASSCODE, MODAL_LOGIN_PASSWORDLESS, useGetModalDataByName} from '@store/client/store.modals';

const ErrorComponent = dynamic(() => import('@components/Error/DefaultErrorPage'), {ssr: false});

/* Passwordless */
const LoginModalManager = dynamic(() => import('@scenes/LoginPage/Modal').then(mod => mod.LoginModalManager), {ssr: false});
const LoginPasscodeModal = dynamic(() => import('@scenes/LoginPage/Modal').then(mod => mod.LoginPasscodeModal), {ssr: false});

/* Client Setup */
const ClaimStoreCreditsModal = dynamic(() => import('@components/Modals/ClaimStoreCreditsModal'), {ssr: false});
const ErrorBoundary = ClientBugsnag();
NProgress.configure({showSpinner: false});

/*
 * Allows us to use devTools in Production
 * */
const ReactQueryDevToolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(devtools => ({
    default: devtools.ReactQueryDevtools,
  })),
);

/**
 * @remarks
 * Please NOTE: If you have a page that does NOT require split or any external apis, we can
 * Start thinking about loading this file in a conditional
 *
 * @param {IMyAppProps} props
 * @returns {any}
 * @constructor
 */

const App = (props: IMyAppProps) => {
  /* State: Global Base Level */
  const {setSplitAppLevel} = useAppStoreActions();

  /* Client state selectors */
  const modalData = useGetModalDataByName(MODAL_LOGIN_PASSCODE);
  const passwordlessModal = useGetModalDataByName(MODAL_LOGIN_PASSWORDLESS);
  const normalLoginModal = useAppOpenSlideModalLogin();

  /* Local */
  const {Component, pageProps} = props;

  /* local state */
  const [, setAjsAnonymousId] = useState(index.core.key);
  const [showDevTools, setShowDevTools] = useState(false);
  const [shouldRun, setShouldRun] = useState(false);

  /* Hooks */
  const router = useRouter();
  const {email: customerEmail, creditToken} = router.query;

  /* Poll for AJS Anonymous id for Split. We should set this ourslves if doesn't exist. Will create ticket */
  useIntervalConditionally(
    () => {
      const hasAjsCookie = getKeyAJSKeyForConfig() !== 'anonymous';
      if (hasAjsCookie) {
        setSplitAppLevel(false);
        setAjsAnonymousId(getKeyAJSKeyForConfig());
        return true; // This will stop the interval once the anonymousId is found
      }
      return false; // This will keep the interval running
    },
    200,
    20, // we'll wait for 4 seconds
    shouldRun,
  );

  /* Methods */
  /* Start route change */
  const handleRouteStart = (url: string) => {
    NProgress.start();

    // This allows us to extract out global concerns. When a page is left/leaving.
    GlobalSettingsUnLoad();
  };

  /* Complete route change */
  const handleRouteComplete = () => {
    NProgress.done();
  };

  /* Fire initial site loading page tracking */
  useEffect(() => {
    if (router.isReady) {
      ClassSegment?.page(router.asPath).done();
      gtag.pageview(router.asPath);
    }
  }, [router.asPath, router.isReady]);

  /* Listeners */
  router.events?.on('routeChangeStart', handleRouteStart);
  router.events?.on('routeChangeComplete', handleRouteComplete);

  useEffect(() => {
    if (process.browser) {
      // @ts-ignore
      window.toggleDevTools = () => setShowDevTools(show => !show);
    }
  }, []);

  /* Get at Segment cookie */
  useEffect(() => {
    window?.analytics?.ready(() => {
      setShouldRun(true);
    });

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', NProgress.done);
    };
  }, []);

  /* Track the page views on page change */
  useEffect(() => {
    if (router.isReady) {
      /* route.on does not fire on first load. Capture global concerns here */
      GlobalSettingsLoad(router, {firstLoad: true});
    }
  }, [router.isReady]);

  return (
    <>
      <DefaultSeo {...SEO} />
      <ErrorBoundary FallbackComponent={attrs => <ErrorComponent {...attrs} />}>
        <SplitFactoryProvider config={index} updateOnSdkUpdate={true} updateOnSdkTimedout={false}>
          <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={pageProps.dehydratedState}>
              {/* App Level behaviors ie, tracking, notifications etc... NON-UI */}
              <AppLevelSideEffects pageProps={pageProps} />
              {/* Main Body of App */}
              <div className={cn(props?.fontClass, 'appContainer')}>
                {Component.Layout ? Component.Layout(<Component {...pageProps} />) : <Component {...pageProps} />}
                {/* App wide modals */}
                {/* Clanky but we don't want to include anything in bundle we don't have to */}
                {(normalLoginModal || passwordlessModal?.visible) && <LoginModalManager showLogo showCloseIcon />}
                {modalData?.visible && <LoginPasscodeModal />}
                {customerEmail && creditToken && <ClaimStoreCreditsModal />}
              </div>
              {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}

              {showDevTools ? (
                <React.Suspense fallback={null}>
                  <ReactQueryDevToolsProduction />
                </React.Suspense>
              ) : null}
            </HydrationBoundary>
          </QueryClientProvider>
        </SplitFactoryProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
